console.log("Greetings from Tekenwerken De Vos ");

import "lazysizes";
//barba function
barba.init({
  sync: true,
  transitions: [
    {
      name: "home",
      to: { namespace: ["home"] },
      async leave(data) {
        const done = this.async();
        done();
      },
      async enter(data) {
        home();
      },
      async once(data) {
        home();
      },
    },
    {
      name: "projecten",
      to: { namespace: ["projecten"] },
      async leave(data) {
        const done = this.async();
        done();
      },
      async enter(data) {
        Projecten();
        LogoSmall();
        Main();
      },
      async once(data) {
        Projecten();
        LogoSmall();
        Main();
      },
    },
    {
      name: "about",
      to: { namespace: ["about"] },
      async leave(data) {
        const done = this.async();
        done();
      },
      async enter(data) {
        LogoSmall();
        Posterstatic();
        SecNav();
        About();
      },
      async once(data) {
        LogoSmall();
        Posterstatic();
        SecNav();
        About();
      },
    },
    {
      name: "project",
      to: { namespace: ["project"] },
      async leave(data) {
        const done = this.async();
        done();
      },
      async enter(data) {
        Project();
        loadswiper();
        info();
        Video();
        backbutton();
      },
      async once(data) {
        Project();
        loadswiper();
        info();
        Video();
        backbutton();
      },
    },
    {
      name: "homeproject",
      from: { namespace: ["home"] },
      to: { namespace: ["projecten"] },
      async leave(data) {
        const done = this.async();
        done();
      },
      async enter(data) {
        LogoSmall();
        SecNav();
        Main();
      },
      async once(data) {
        LogoSmall();
        SecNav();
        Main();
      },
    },
    {
      name: "tohome",
      from: { namespace: ["projecten", "project", "about"] },
      to: { namespace: ["home"] },
      async leave(data) {
        const done = this.async();
        done();
      },
      async enter(data) {
        toHome();
        LogoBig();
        HideSecNav();
      },
      async once(data) {
        toHome();
        LogoBig();
        HideSecNav();
      },
    },
    {
      name: "toproject",
      from: { namespace: ["projecten"] },
      to: { namespace: ["project"] },
      async leave(data) {
        const done = this.async();
        done();
      },
      async enter(data) {
        toProject();
        loadswiper();
        info();
        Video();
        backbutton();
      },
      async once(data) {
        toProject();
        Video();
      },
    },
  ],
});

/// swiper initialise
function loadswiper() {
  new Swiper(".swiper", {
    isBeginning: true,
    loop: true,
    slidesPerView: "auto",
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}

// toggleFilter
function Video() {
  const cond = document.getElementById("toggleVideo") || false;
  if (cond) {
    var toggleVideo = document.getElementById("toggleVideo");
    var videoLink = toggleVideo.getAttribute("data-atr");
    var imgbtn = toggleVideo.getAttribute("data-id");
    var playbtn = toggleVideo.getAttribute("data-src");
    var IframeContainer = document.getElementById("iframe__container");
    var Iframe = document.getElementById("iframe");
    function ToggleIframe() {
      IframeContainer.classList.toggle("show");
      Iframe.src = videoLink;
      if (IframeContainer.classList.contains("show")) {
        toggleVideo.innerHTML = imgbtn;
      } else {
        toggleVideo.innerHTML = playbtn;
      }
    }
    toggleVideo.addEventListener("click", ToggleIframe);
  } else {
  }
}

// back button
function backbutton() {
  var goback = document.getElementById("goback");
  // old code
  // function goBack() {
  //   window.history.back();
  // }
  function goBack() {
    // Get the ID of the current page (= not working!!)
    // const id = document.getElementById("pageid").getAttribute("page-id");
    const history = barba.history;
    if (history.size > 1) {
      let prevUrl = history.previous.url; // Get the previous URL from Barba's history
      // console.log(`${prevUrl}#${id}`);
      barba.go(prevUrl); // Use Barba's method to navigate
    } else {
      barba.go("/nl/projecten");
      // barba.go(`/nl/projecten#${id}`);
    }
  }
  goback.addEventListener("click", goBack);
}

// toggleInfo
function info() {
  var toggleInfo = document.getElementById("toggleInfo");
  var slider = document.getElementById("swiper");
  var videobtn = document.getElementById("toggleVideo");
  var hide = toggleInfo.getAttribute("data-id");
  var show = toggleInfo.getAttribute("data-atr");
  function ToggleInfoField() {
    toggleInfo.classList.toggle("open");
    document.getElementById("info").classList.toggle("show");
    if (toggleInfo.classList.contains("open")) {
      toggleInfo.innerHTML = hide;
      slider.classList.add("darker");
      videobtn.classList.add("hide");
    } else {
      toggleInfo.innerHTML = show;
      slider.classList.remove("darker");
      videobtn.classList.remove("hide");
    }
  }
  toggleInfo.addEventListener("click", ToggleInfoField);
}

// animation functions
function home() {
  var tl = gsap.timeline();
  tl.to(".logo", { duration: 0, display: "flex" });
  tl.to(".start", { duration: 0, display: "flex" });
}
function About() {
  var tl = gsap.timeline();
  tl.to(".start", { duration: 0, display: "none" });
  tl.to(".logo", { duration: 0, display: "flex" });
  tl.to(".static", { translateY: 0 });
}
function Posterstatic() {
  var tl = gsap.timeline();
  tl.to(".poster__static", { opacity: 1 });
}
function toProject() {
  var tl = gsap.timeline();
  tl.to(".start", { duration: 0, display: "none" });
  tl.to(".secnav", { duration: 0, translateY: 0 });
  tl.to(".project", { duration: 0.2, opacity: 1 });
}
function toHome() {
  var tl = gsap.timeline();
  tl.to(".sun", { duration: 0, display: "none" });
  tl.to(".start, .logo", { duration: 0, display: "flex" });
  tl.to(".main", { translateY: 0, marginBottom: 0 });
  tl.to(".main", { duration: 0.1, opacity: 1 });
  tl.to(".project", { duration: 0, opacity: 0 });
}
function Projecten() {
  var tl = gsap.timeline();
  tl.to(".start", { duration: 0, display: "none" });
  tl.to(".secnav", { duration: 0, translateY: 0 });
}
function Project() {
  var tl = gsap.timeline();
  tl.to(".start", { duration: 0, display: "none" });
  tl.to(".secnav", { duration: 0, translateY: 0 });
  tl.to(".project", { duration: 0, opacity: 1 });
}
function LogoBig() {
  var tl = gsap.timeline();
  tl.to(".logo", { duration: 0.4, translateY: 0 });
}
function LogoSmall() {
  var tl = gsap.timeline();
  tl.to(".logo", { translateY: -200 });
}
function Main() {
  var tl = gsap.timeline();
  tl.to(".start", { duration: 0, display: "none" });
  tl.to(".main", { translateY: 0, marginBottom: 0 });
  tl.to(".main", { duration: 0.1, opacity: 1 });
  tl.to(".main", { duration: 0.1, position: "relative" });
}
function SecNav() {
  var tl = gsap.timeline();
  tl.to(".secnav", { duration: 0.4, translateY: 0 });
}
function HideSecNav() {
  var tl = gsap.timeline();
  tl.to(".secnav", { duration: 0.3, translateY: -250 });
}
